import axios from 'axios';
import * as actionTypes from './actionTypes';

export const authStart = () => ({
  type: actionTypes.AUTH_START
});

export const authSuccess = (authData) => ({
  type: actionTypes.AUTH_SUCCESS,
  authData
});

export const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  error
});

export const setLang = (lang) => ({
  type: actionTypes.SET_LANG,
  lang
});

export const updateUrlName = (urlName) => ({
  type: actionTypes.UPDATE_URL_NAME,
  urlName
});

export const setUnlockScreen = (bool) => ({
  type: actionTypes.SET_UNLOCKSCREEN,
  unlockscreen: bool
});

export const loadBrand = () => ({
  type: actionTypes.LOAD_BRAND
});

export const setBrand = (brand) => ({
  type: actionTypes.SET_BRAND,
  brand
});

export const logout = () => {
  axios.delete('/logout')
    .then(() => {
      localStorage.removeItem('wasLoggedIn');
      document.getElementById('crisp-chatbox')?.remove();
    })
    .catch(() => { });

  try {
    localStorage.removeItem('wasLoggedIn');
  } catch (e) {
    console.log(e);
  }

  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const changeUnread = (unread) => ({
  type: actionTypes.CHANGE_UNREAD,
  unread
});

// eslint-disable-next-line no-unused-vars
export const auth = (email, password, history, unlockscreen = false, clientauth = false, zoomCode = '', next = '') => (dispatch) => {
  // dispatch(authStart());
  const authData = {
    user: {
      email,
      password
    },
    clientauth
  };

  axios.post('login', authData)
    .then((response) => {
      const data = {
        accountState: response.data.accountState,
        token: response.data.token,
        initials: response.data.initials,
        name: response.data.name,
        email: response.data.email,
        instructor: response.data.instructor,
        client: response.data.client,
        isNew: response.data.isNew,
        address: response.data.address,
        zipCode: response.data.zipCode,
        city: response.data.city,
        admin: response.data.admin,
        role: response.data.role,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        urlName: response.data.urlName,
        forms: response.data.forms,
        assigned_workflows: response.data.assigned_workflows,
        avatar: response.data.avatar,
        subdomain: response.data.subdomain,
        timezone: response.data.timezone,
        getting_started: response.data.getting_started,
        lang: response.data.lang,
        active: response.data.active,
        hasStripe: response.data.hasStripe,
        unread: response.data.unread,
        canDoOnlineEvent: response.data.canDoOnlineEvent,
        unpaidInvoices: response.data.unpaidInvoices,
        plan: response.data.plan,
        planType: response.data.planType,
        hide_invoice_option: response.data.hide_invoice_option,
        hasPaymentMethod: response.data.hasPaymentMethod,
        freeMember: response.data.freeMember,
        subscriptions: response.data.subscriptions,
        basicMember: response.data.basicMember,
        premiumMember: response.data.premiumMember,
        premiumPlusMember: response.data.premiumPlusMember,
        hasInvoiceAccess: response.data.hasInvoiceAccess,
        bookingPageAccess: response.data.bookingPageAccess,
        mediaLibraryAccess: response.data.mediaLibraryAccess,
        deployVersion: response.data.deployVersion,
        currency: response.data.currency,
        unlockscreen
      };

      try {
        localStorage.setItem('wasLoggedIn', 'true');
      } catch (e) {
        console.log(e);
      }
      // }

      if (zoomCode) {
        axios.get(`/v1/zoom?code=${zoomCode}`)
          .then(() => {
          })
          .catch(() => {
          });
      }

      dispatch(authSuccess(data));
    })
    .catch((err) => {
      dispatch(authFail(err));
    });
};

export const authCheckDone = () => ({
  type: actionTypes.AUTH_CHECK_DONE
});

export const authSessionExpired = () => ({
  type: actionTypes.AUTH_SESSION_EXPIRED
});

export const authCheckState = (url = '', history = null) => (dispatch) => {
  // let wasLoggedIn = false;

  // try {
  //   wasLoggedIn = JSON.parse(localStorage.getItem('wasLoggedIn'));
  // } catch (e) {
  //   console.log(e);
  // }

  // if (wasLoggedIn) {
  axios.get('/v1/users/info').then((response) => {
    if (response.status === 200) {
      const user = response.data;
      const userData = {
        deployVersion: user.deployVersion,
        firstName: user.firstName,
        lastName: user.lastName,
        currency: user.currency,
        accountState: user.accountState,
        token: user.token,
        initials: user.initials,
        name: user.name,
        email: user.email,
        address: user.address,
        zipCode: user.zipCode,
        city: user.city,
        instructor: user.instructor,
        client: user.client,
        admin: user.admin,
        forms: user.forms,
        urlName: user.urlName,
        active: user.active,
        role: user.role,
        getting_started: user.getting_started,
        avatar: user.avatar,
        timezone: user.timezone,
        lang: user.lang,
        unread: user.unread,
        hide_invoice_option: user.hide_invoice_option,
        canDoOnlineEvent: user.canDoOnlineEvent,
        unpaidInvoices: user.unpaidInvoices,
        hasInvoiceAccess: user.hasInvoiceAccess,
        bookingPageAccess: user.bookingPageAccess,
        mediaLibraryAccess: user.mediaLibraryAccess,
        plan: user.plan,
        hasStripe: user.hasStripe,
        planType: user.planType,
        subdomain: user.subdomain,
        subscriptions: user.subscriptions,
        hasPaymentMethod: user.hasPaymentMethod,
        freeMember: user.freeMember,
        basicMember: user.basicMember,
        isNew: user.isNew,
        premiumMember: user.premiumMember,
        assigned_workflows: user.assigned_workflows,
        premiumPlusMember: user.premiumPlusMember
      };
      dispatch(authSuccess(userData));

      const next = localStorage.getItem('next');
      if ((url || next) && history) {
        if (url.includes('//')) {
          // eslint-disable-next-line
          url = url.replace('/', '');
        }
        history(url || next);
      }
      setTimeout(() => {
        dispatch(authCheckState());
      }, 5 * 60 * 1000);
    }
    // eslint-disable-next-line no-unused-vars
  }).catch((err) => {
    dispatch(logout());
  });
  // } else {
  //   dispatch(authCheckDone());
  // }
};
