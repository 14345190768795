import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Page, Layout, Toast, SkeletonPage, ContextualSaveBar, Stack, Thumbnail, Button, DropZone, Icon, TextStyle, Tooltip, Heading, Tabs, TextField } from '@shopify/polaris';
import { HideMinor, ViewMinor, EditMinor, InfoMinor } from '@shopify/polaris-icons';
import axios from 'axios';
// import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Banners from '../Shared/Banners';
import Comments from '../Comments/Comments';
// import SignedInLessons from '../Shared/SignedInLessons';
import { stateConverter } from '../FilterEngine/filterParams';
// import EditClient from './EditClient';
import StackList from '../Shared/StackList/StackList';
import CustomFields from '../CustomFields/CustomFields';
import Calendar from '../Graphs/Calendar';
// import ClientEvents from './ClientEvents';
import { getTabId, getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';
import Tags from '../Tags/Tags';
import TagModifier from '../Tags/TagModifier';
import usePrevious from '../../hooks/usePrevious';
import Products from './Products';
import Events from '../Events/Events';
import StaffSectionBlocks from '../PublicProfile/StaffSectionBlocks';
import Schedule from '../Schedule/Schedule';
// import ClientConversation from './ClientConversation';
// import StatusBadge from '../Shared/StatusBadge';

const StaffAccount = (props) => {
  const [avatar, setAvatar] = useState('');
  const [signupFields, setSignupFields] = useState([]);
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [init, setInit] = useState(false);
  const [initTags, setInitTags] = useState(false);
  const [customFieldLang, setCustomFieldLang] = useState('en');
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProfileTags, setSelectedProfileTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [initialProfileTags, setInitialProfileTags] = useState([]);
  const [customFieldError, setCustomFieldError] = useState(false);
  const [showUserAttributes, setShowUserAttributes] = useState(false);
  const [staff, setStaff] = useState({ address: {} });
  const [banner, setBanner] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profileCustomFields, setProfileCustomFields] = useState([]);
  const [schedule, setSchedule] = useState([]);

  const [initLanguages, setInitLanguages] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [initialLanguages, setInitialLanguages] = useState([]);

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();
  const matchParams = useParams();

  const tabs = [
    {
      id: 'details',
      content: t('client.overview'),
      accessibilityLabel: t('client.overview'),
      panelID: 'details'
    },
    {
      id: 'products',
      content: t('products.singular'),
      accessibilityLabel: t('products.singular'),
      panelID: 'products'
    },
    {
      id: 'events',
      content: t('calendar.events'),
      accessibilityLabel: t('calendar.events'),
      panelID: 'events'
    },
    {
      id: 'notes',
      content: t('comment.comments'),
      accessibilityLabel: t('comment.comments'),
      panelID: 'notes'
    },
    {
      id: 'bookingPage',
      content: t('staff.booking_page'),
      accessibilityLabel: t('staff.booking_page'),
      panelID: 'bookingPage'
    },
    {
      id: 'availability',
      content: t('product.availability'),
      accessibilityLabel: t('product.availability'),
      panelID: 'availability'
    }
  ];

  const [tableFilters, setTableFilters] = useState(stateConverter(location));

  const fetchClient = useCallback(() => {
    axios.get(`/v1/staffs/${matchParams.token}`)
      .then((response) => {
        if (response.data.avatar) setAvatar(response.data.avatar);
        setStaff(response.data);
        setSelectedTags(response.data.selectedTags);
        setSignupFields(response.data.signup_fields);
        setInitialTags(response.data.selectedTags);
        setSelectedLanguages(response.data.selectedLanguages);
        setInitialLanguages(response.data.selectedLanguages);
        setSchedule(JSON.parse(response.data.schedule));
        setLoading(false);
        setProfileCustomFields(response.data.profile_custom_fields);
        setSelectedProfileTags(response.data.selectedProfileTags);
        setInit(true);
      })
      .catch((err) => {
        setBanner([{ title: t('client.cannot_get_list'), status: 'critical', details: err.response.data.error }]);
        setLoading(false);
      })
      .then(() => {
        setInitTags(true);
        setInitLanguages(true);
      });
  }, [matchParams.token, t]);

  const handleActiveToggle = (id, action) => {
    if (!window.confirm(action === 'inactivate' ? t('staff.deactivate') : t('staff.activate'))) {
      return;
    }

    axios.post(`/v1/staffs/${id}/${action}`)
      .then(() => {
        setStaff({ ...staff, accountState: staff.active ? 'inactive' : 'active', active: !staff.active });
        setBanner([{ title: `User ${action}d`, status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: `Cannot ${action} user`, status: 'critical', details: error.response.data.errors }]);
      });
  };

  const fetchCustomFields = useCallback(() => {
    axios.get(`/v1/staffs/${matchParams.token}/custom_fields`)
      .then((res) => {
        setCustomFields(res.data);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.error }]);
      });
  }, [matchParams.token, t]);

  const updateLanguages = useCallback(() => {
    setInitLanguages(false);

    const params = {
      user: {
        languages_attributes: TagModifier(selectedLanguages, initialLanguages)
      }
    };

    axios.patch(`/v1/users/${matchParams.token}/update_languages`, params)
      .then((response) => {
        setSelectedLanguages(response.data.selectedLanguages);
        setInitialLanguages(response.data.selectedLanguages);
        setInitLanguages(true);
      })
      .catch(() => {
      });
    // eslint-disable-next-line
  }, [props.userId, selectedLanguages]);

  const languageDisplay = (
    <Tags
      selectedTags={selectedLanguages}
      setSelectedTags={setSelectedLanguages}
      tagUrl={`/v1/users/${matchParams.token}/available_languages`}
    />
  );

  const previousLanguages = usePrevious(selectedLanguages);

  useEffect(() => {
    if (initLanguages && JSON.stringify(previousLanguages) !== JSON.stringify(selectedLanguages)) {
      updateLanguages();
    }
    // eslint-disable-next-line
  }, [selectedLanguages]);

  const pageActions = () => {
    if (!staff || loading) {
      return {};
    }

    const toggleContent = staff.active ? 'Inactivate' : 'Activate';
    const content = staff.active ? t('client.inactivate') : t('client.activate');

    const actions = {
      icon: staff.active ? HideMinor : ViewMinor,
      content,
      destructive: true,
      onAction: () => handleActiveToggle(staff.token, toggleContent.toLowerCase())
    };

    return actions;
  };

  useEffect(() => {
    fetchClient();
  }, [fetchClient]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  const updateTags = useCallback(() => {
    setInit(false);

    const params = {
      staff: {
        tags_attributes: TagModifier(selectedTags, initialTags)
      }
    };

    axios.patch(`/v1/staffs/${matchParams.token}`, params)
      .then((response) => {
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setInit(true);
      })
      .catch(() => {
      });
  }, [matchParams.token, selectedTags, initialTags]);

  const previousTags = usePrevious(selectedTags);

  useEffect(() => {
    if (init && JSON.stringify(previousTags) !== JSON.stringify(selectedTags)) {
      updateTags();
    }
    // eslint-disable-next-line
  }, [selectedTags]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const descriptionList = () => {
    const items = [{
      term: t('user.name'),
      description: staff.name
    }, {
      term: t('user.email'),
      description: staff.email
    }, {
      term: t('address.phone_number'),
      description: staff.phoneNumber
    }, {
      term: 'Language',
      description: staff.lang
    }, {
      term: t('address.addresse'),
      description: staff.address && Object.keys(staff.address).length ? (
        <>
          <div>{staff.address.attentionName}</div>
          <div>{staff.address.address}</div>
          <div>{staff.address.addressLineTwo}</div>
          <div>{`${staff.address.city}, ${staff.address.state || ''} ${staff.address.zip_code || ''}`}</div>
        </>
      ) : null
    }, {
      term: 'Organization',
      description: staff.businesstype
    }];

    return items;
  };

  const staffDescriptionList = () => customFields.map((customField) => {
    // eslint-disable-next-line no-underscore-dangle
    if (customField._destroy === '1') return '';
    return {
      term: (
        <Stack spacing="tight">
          <TextStyle>{customField.label}</TextStyle>
          {customField.editable_by_staff && <Tooltip content={t('custom_fields.editable')}><Icon source={EditMinor} color="base" /></Tooltip>}
          {customField.viewable_by_staff && <Tooltip content={t('custom_fields.viewable')}><Icon source={ViewMinor} color="base" /></Tooltip>}
        </Stack>
      ),
      description: customField.value
    };
  });

  const displaySignupFields = () => signupFields.map((customField, idx) => (
    customField.value && (
      <Card.Section key={idx}>
        <TextField
          label={(<div dangerouslySetInnerHTML={{ __html: customField.label }} />)}
          value={customField.value}
          disabled
          onChange={() => { }}
        />
      </Card.Section>
    )
  ));

  const updateCustomFields = (data) => {
    const params = {
      staff: {
        custom_fields_attributes: data
      }
    };

    axios.patch(`/v1/staffs/${matchParams.token}/attributes`, params)
      .then((res) => {
        setCustomFields(res.data.custom_fields);
        setBanner([{ title: t('user.update_successful'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const updateAvatar = useCallback((a) => {
    const formData = new FormData();

    if (a?.size > 0) {
      formData.append('avatar', a);
    }

    if (!a) {
      formData.append('remove_logo', true);
    }

    axios.patch(`/v1/staffs/${matchParams.token}/avatar`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        setAvatar(response.data.avatar);
        setBanner([{ title: t('user.update_successful'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  }, [matchParams.token, t]);

  // const editUserInfo = () => {
  //   setShowClientEdit(true);
  // };

  const staffEditModalAttributes = showUserAttributes
    ? (
      <CustomFields
        active={showUserAttributes}
        close={() => setShowUserAttributes(false)}
        handleSubmit={updateCustomFields}
        staff={staff}
        customFields={customFields}
        title={t('custom_fields.plural')}
        editableTitle={t('staff.staff')}
      />
    ) : null;

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
    } else {
      setAvatar(acceptedFiles[0]);
      updateAvatar(acceptedFiles[0]);
    }
  }, [updateAvatar, t]);
  const uploadedFile = avatar && (
    <Stack alignment="center">
      {avatar?.length ? <Thumbnail size="large" name="avatar" source={avatar} /> : ''}
      <Button
        plain
        onClick={() => {
          setAvatar('');
        }}
      >
        {t('shared.change')}
      </Button>
    </Stack>
  );

  const staffAvatar = !avatar?.length ? (
    <>
      <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleDropZoneDrop}>
        <DropZone.FileUpload />
      </DropZone>
      <br />
    </>
  ) : uploadedFile;

  const detailsTab = (
    loading ? <SkeletonPage /> : (
      <>
        {staffEditModalAttributes}
        <Banners banners={banner} onDismissBanner={dismissBanner} />
        <Layout>
          <Layout.Section oneHalf>
            <Card sectioned title={t('client.personal_info')}>
              <StackList items={descriptionList()} />
            </Card>
            <Card
              sectioned
              title={(
                <Stack wrap={false} spacing="tight" alignment="center">
                  <Heading>{t('custom_fields.plural')}</Heading>
                  <Tooltip content={t('custom_fields.help_staff')}>
                    <Icon color="highlight" source={InfoMinor} />
                  </Tooltip>
                </Stack>
              )}
              actions={{ content: t('shared.edit'), onAction: () => setShowUserAttributes(true) }}
            >
              <StackList items={staffDescriptionList()} />
              {displaySignupFields()}
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <Card
              sectioned
              title="Tags"
            >
              <Tags
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                tagUrl={`/v1/staffs/${matchParams.token}/available_staff_account_tags`}
              />
            </Card>
            <Card
              sectioned
              title={t('user.languages')}
            >
              {languageDisplay}
            </Card>
            <Card
              sectioned
              actions={avatar?.length ? [{
                content: t('shared.change'),
                onAction: () => setAvatar('')
              }, {
                content: t('shared.delete'),
                onAction: () => updateAvatar('')
              }] : ''}
            >
              {avatar ? (
                <img
                  alt=""
                  width="100%"
                  style={{
                    objectFit: 'scale-down',
                    objectPosition: 'center',
                    maxHeight: '400px'
                  }}
                  src={avatar}
                />
              ) : staffAvatar}
            </Card>
            {staff.currentYear ? (
              <div style={{ height: '200px' }}>
                <Calendar data={staff.currentYear} />
              </div>
            ) : null}
          </Layout.Section>
        </Layout>
      </>
    )
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const product = query.get('product');

    if (product) setNavigateTo(`/products/${product}?tab=Staffs`);
    // eslint-disable-next-line
  }, []);

  const handleProfileCustomFieldChange = (idx) => (field) => (value) => {
    const temp = [...profileCustomFields];
    temp[idx][field] = value;

    setProfileCustomFields(temp);
    setShowSaveBar(true);
  };

  const deleteCustomField = (id) => {
    const temp = [...profileCustomFields];
    // eslint-disable-next-line
    temp[id]._destroy = '1';
    setProfileCustomFields([...temp]);
    setShowSaveBar(true);
  };

  const addCustomField = () => {
    setProfileCustomFields([...profileCustomFields, {
      language: customFieldLang,
      order_number: customFields.length
    }]);
  };

  const handleDragnDropChange = (items) => {
    setProfileCustomFields(items);
    setShowSaveBar(true);
  };

  const handleScheduleChange = (data) => {
    if (!init) return;

    setShowSaveBar(true);
    setSchedule(data);
  };

  const handleProfileEdit = () => {
    const profileData = new FormData();

    profileCustomFields.forEach((cf, i) => {
      if (cf.id) profileData.append(`user[profile_template_fields_attributes][${i}][id]`, cf.id);
      profileData.append(`user[profile_template_fields_attributes][${i}][label]`, cf.label);
      profileData.append(`user[profile_template_fields_attributes][${i}][value]`, cf.value);
      profileData.append(`user[profile_template_fields_attributes][${i}][order_number]`, cf.order_number);
      profileData.append(`user[profile_template_fields_attributes][${i}][language]`, cf.language);
      // eslint-disable-next-line
      if (cf._destroy) {
        // eslint-disable-next-line
        profileData.append(`user[profile_template_fields_attributes][${i}][_destroy]`, cf._destroy);
      }
    });

    profileData.append('user[calendar][schedule]', JSON.stringify(schedule));

    axios.patch(`/v1/users/${matchParams.token}/profile_template`, profileData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(() => {
        fetchClient();
        toggleToastActive(t('shared.saved'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      })
      .then(() => {
        setShowSaveBar(false);
      });
  };

  const tagDisplay = (
    <Tags
      language={customFieldLang}
      selectedTags={selectedProfileTags}
      setSelectedTags={setSelectedProfileTags}
      tagUrl={`/v1/users/${matchParams.token}/available_user_booking_tags`}
    />
  );

  const updateProfileTags = useCallback(() => {
    setInit(false);

    const params = {
      staff: {
        booking_tags_attributes: TagModifier(selectedProfileTags, initialProfileTags, customFieldLang)
      }
    };

    axios.patch(`/v1/staffs/${matchParams.token}`, params)
      .then((response) => {
        setSelectedProfileTags(response.data.selectedProfileTags);
        setInitialProfileTags(response.data.selectedProfileTags);
        setInit(true);
      })
      .catch(() => {
      });
  }, [matchParams.token, selectedProfileTags, initialProfileTags, customFieldLang]);

  const previousProfileTags = usePrevious(selectedProfileTags);

  useEffect(() => {
    if (initTags && JSON.stringify(previousProfileTags) !== JSON.stringify(selectedProfileTags) && JSON.stringify(selectedProfileTags) !== JSON.stringify(initialProfileTags)) {
      updateProfileTags();
    }
    // eslint-disable-next-line
  }, [selectedProfileTags]);

  return (
    <Page
      title={`${t('staff.staff')} ${staff.name ? '-' : ''} ${staff.name || ''}`}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => (navigateTo ? history(navigateTo) : history('/staff_accounts')) }]}
      fullWidth
      separator
      primaryAction={pageActions()}
    >
      {toastMarkup}
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => handleProfileEdit(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => {
          onTabSelect({ history, location }, tabs, tabIndex, setTableFilters);
          if (tabIndex === 2) {
            history(`/staffs/${matchParams.token}?tabs=Events&staff=${matchParams.token}`);
          }
        }}
      >
        <br />
        {/* Details */}
        {getTabIndex(tabs, tableFilters.tab) === 0 && (
          detailsTab
        )}
        {getTabIndex(tabs, tableFilters.tab) === 1 && (
          <Products staffName={staff.name} />
        )}
        {/* Events */}
        {getTabIndex(tabs, tableFilters.tab) === 2 && (
          <Events title="" />
        )}
        {/* Notes */}
        {/* Assignments */}
        {getTabIndex(tabs, tableFilters.tab) === 3 && (
          <Comments
            commentableId={staff.token}
            commentablePath="staffs"
            tooltip={t('staffs.secure')}
          />
        )}
        {getTabId(tabs, tableFilters.tab) === 'bookingPage' && (
          <StaffSectionBlocks
            customFields={profileCustomFields || []}
            handleCustomFieldChange={handleProfileCustomFieldChange}
            deleteCustomField={deleteCustomField}
            addCustomField={addCustomField}
            error={customFieldError}
            tagDisplay={tagDisplay}
            setError={setCustomFieldError}
            setCustomFieldLang={setCustomFieldLang}
            customFieldLang={customFieldLang}
            setCustomFields={handleDragnDropChange}
            title={t('custom_fields.plural')}
          />
        )}
        {getTabId(tabs, tableFilters.tab) === 'availability' && (
          <Card>
            <Schedule value={schedule || []} handleChange={handleScheduleChange} useHooks />
          </Card>
        )}
      </Tabs>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  canAccessInvoices: !['', 'Free'].includes(state.auth.plan),
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  unread: state.auth.unread
});

export default connect(mapStateToProps)(StaffAccount);
