/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.min.css';
import { Model, setLicenseKey } from 'survey-core';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';
import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';

import * as XLSX from 'xlsx';

const TabulatorP = (props) => {
  setLicenseKey(
    'NmY0MzZiMjctNTk1YS00OGQ2LWFlMDQtYjVjN2YxMDk4ZTNkOzE9MjAyNS0xMC0wMywyPTIwMjUtMTAtMDMsND0yMDI1LTEwLTAz'
  );
  applyPlugin(jsPDF);

  const [survey, setSurvey] = useState(null);
  const [surveyDataTable, setSurveyDataTable] = useState(null);
  const [surveyResults, setSurveyResults] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!survey && props.records?.[0]?.data) {
      try {
        const json = JSON.parse(props.records[0].data);
        if (json) {
          const s = new Model(json);
          setSurvey(s);
        }
      } catch (error) {
        console.error('Invalid JSON data:', error);
        setHasError(true);
      }
    }
  }, [survey, props.records]);

  useEffect(() => {
    if (!surveyResults && !hasError) {
      try {
        setSurveyResults(props.records.flatMap((m) => JSON.parse(JSON.parse(m.answerData))));
      } catch (error) {
        console.error('Error parsing answer data:', error);
        setHasError(true);
      }
    }
  }, [surveyResults, props.records, hasError]);

  useEffect(() => {
    if (!surveyDataTable && survey && surveyResults) {
      const sdt = new Tabulator(
        survey,
        surveyResults,
        { jspdf: jsPDF, xlsx: XLSX }
      );
      setSurveyDataTable(sdt);
    }
  }, [survey, surveyResults, surveyDataTable]);

  useEffect(() => {
    if (surveyDataTable) {
      surveyDataTable.render('surveyDataTable');
      return () => {
        if (document.getElementById('surveyDataTable')) {
          document.getElementById('surveyDataTable').innerHTML = '';
        }
      };
    }
    return undefined;
  }, [surveyDataTable]);

  if (hasError) {
    return (
      <div>Not available for old forms, try new forms</div>
    );
  }

  return (
    <div id="surveyDataTable" />
  );
};

export default TabulatorP;
