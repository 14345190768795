/* eslint-disable eqeqeq */
import React, { useState, useCallback, useEffect } from 'react';
import { Settings } from 'luxon';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import luxon2Plugin from '@fullcalendar/luxon2';
import rrulePlugin from '@fullcalendar/rrule';
import allLocales from '@fullcalendar/core/locales-all';
import { Stack, TextStyle, Toast, Tooltip } from '@shopify/polaris';
import '../Calendar.scss';
import * as actions from '../../../store/actions/index';
import ClientEventEdit from './ClientEventEdit';
import ModalService from '../../Shared/Services/ModalService';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';

const confirmationModalRef = React.createRef();

const ClientCalendar = (props) => {
  // const [calendarViewDates, setCalendarViewDates] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showEventEdit, setShowEventEdit] = useState(false);
  const [eventToEdit, setEventToEdit] = useState({});
  const [date, setDate] = useState({});
  const [events, setEvents] = useState([]);
  const [calendarSettings, setCalendarSettings] = useState({});

  const { t } = useTranslation();
  const matchParams = useParams();

  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const fetchEvents = (start, end) => {
    const params = {
      start,
      end,
      id: matchParams.id
    };

    axios.post('/v1/events/my_events', params)
      .then((response) => {
        props.onSetCalendar({
          id: params.id,
          name: response.data.owner
        });
        response.data.events.forEach((e) => {
          e.start = new Date(e.start);
          e.end = new Date(e.end);
          if (e.participation) {
            e.backgroundColor = '#37db5b';
            e.borderColor = '#49ba49';
          }
        });
        setCalendarSettings(response.data.calendarSettings);
        setEvents(response.data.events);
        debugger;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editEvent = (event) => {
    const updatable = {
      start: event.event.start,
      end: event.event.end,
      id: event.event.id,
      title: event.event.title,
      price: event.event.extendedProps.price,
      instructor: event.event.extendedProps.instructor,
      instructor_email: event.event.extendedProps.instructor_email,
      description: event.event.extendedProps.description,
      recurring: event.event.extendedProps.recurring,
      online: event.event.extendedProps.online,
      rrule: event.dig('event', 'extendedProps', 'meta', 'rrule'),
      meta: event.event.extendedProps.meta,
      spotsRemaining: event.event.extendedProps.spotsRemaining
    };
    debugger;
    setEventToEdit(updatable);
    setShowEventEdit(true);
  };

  const closeModal = () => {
    setShowEventEdit(false);
  };

  const handleSignUp = (event, participation, category) => {
    const data = {
      category,
      date: event.start_time
    };

    if (!participation) {
      axios.post(`/v1/events/${event.id}/participations`, data)
        .then(() => {
          fetchEvents(date.start, date.end);
          toggleToastActive(t('calendar.signed_up'));
        })
        .catch((error) => {
          toggleToastActive(error.response?.data?.errors);
        });
    } else {
      handleUpdate(event, participation, category, 'sign_up');
    }
  };

  const handleSignOut = async (event, participation, category = 'all') => {
    const signOutParticipation = await confirmationModalRef.current.open({
      title: t('calendar.booking_cancellation'),
      description: t('calendar.booking_cancellation_description'),
      cancelButton: t('shared.cancel'),
      submitButton: t('shared.confirm')
    });

    if (!signOutParticipation) {
      return;
    }

    handleUpdate(event, participation, category, 'sign_out');
  };

  const handleUpdate = (event, participation, category, type) => {
    const data = {
      category,
      date: event.start_time
    };

    axios.post(`/v1/events/${event.id}/participations/${participation.id}/${type}`, data)
      .then(() => {
        fetchEvents(date.start, date.end);
        toggleToastActive(t('shared.saved'));
      })
      .catch((error) => {
        toggleToastActive(error.response.data.errors);
      });
  };

  const eventEditModal = showEventEdit
    ? (
      <ClientEventEdit
        active={showEventEdit}
        close={closeModal}
        handleSignUp={handleSignUp}
        handleSignOut={handleSignOut}
        event={eventToEdit}
      />
    ) : null;

  const initialView = () => {
    const width = window.innerWidth;
    if (width > 768) {
      return 'timeGridWeek';
    }

    return 'timeGridDay';
  };

  const setCalendarNavigation = () => {
    const width = window.innerWidth;
    if (width > 768) {
      return 'dayGridMonth,timeGridWeek,timeGridDay,listWeek';
    }

    return 'dayGridMonth,timeGridDay,listDay';
  };

  useEffect(() => {
    Settings.defaultZoneName = calendarSettings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  }, [calendarSettings.timeZone]);

  const updateCalendarDates = (data) => {
    const start = data.view.activeStart;
    const end = data.view.activeEnd;
    setDate({ start, end });
    fetchEvents(start, end);
  };

  const calendarForm = (
    <FullCalendar
      allDaySlot={false}
      firstDay={parseInt((calendarSettings.firstDay || '0'), 10)}
      slotMinTime={calendarSettings.minTime || '00:00:00'}
      slotMaxTime={calendarSettings.maxTime || '24:00:00'}
      businessHours={calendarSettings.businessHours}
      weekends={calendarSettings.weekends}
      nowIndicator
      // expandRows
      datesSet={updateCalendarDates}
      locales={allLocales}
      locale={props.lang}
      initialView={initialView()}
      headerToolbar={{
        left: 'prev,next',
        center: 'title',
        right: setCalendarNavigation()
      }}
      plugins={[
        luxon2Plugin,
        rrulePlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        interactionPlugin
      ]}
      timeZone={calendarSettings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone}
      events={events}
      eventClick={editEvent}
      height="auto"
      // eslint-disable-next-line react/no-unstable-nested-components
      eventContent={(args) => (
        <div style={{ overflow: 'hidden' }}>
          <Stack wrap={false} spacing="tight">
            {args.event.extendedProps.online ? (
              <Tooltip content="Online Event">
                <span className="dot" />
              </Tooltip>
            ) : ''}
            <Stack.Item>
              <TextStyle>{args.timeText}</TextStyle>
            </Stack.Item>
          </Stack>
          <Stack wrap={false}>
            <Stack.Item>
              <TextStyle>{args.event.title}</TextStyle>
            </Stack.Item>
          </Stack>
        </div>
      )}
    />
  );

  return (
    <>
      <ModalService ref={confirmationModalRef}>
        <ConfirmationModal />
      </ModalService>
      {toastMarkup}
      {eventEditModal}
      {calendarForm}
    </>
  );
};

const mapStateToProps = (state) => ({
  calendarId: state.calendar.id,
  lang: state.auth.lang
});

const mapDispatchToProps = (dispatch) => ({
  onSetCalendar: (data) => dispatch(actions.setCalendar(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientCalendar);
