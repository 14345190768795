import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Filters, Card, Loading, Page, SkeletonPage, DataTable, Button, Stack, Popover, ActionList, Toast, Banner, Tooltip, Icon, Badge } from '@shopify/polaris';
import { ExportMinor, PlusMinor, CashDollarMajor, InfoMinor, CircleCancelMajor, MobileCancelMajor, EmailMajor, CircleTickMajor, MobileVerticalDotsMajor, RefreshMajor } from '@shopify/polaris-icons';
import clientFilterActive from '../FilterEngine/Client/clientFilterActive';
import clientFilterDefinition from '../FilterEngine/Client/clientFilterDefinition';
import Banners from '../Shared/Banners';
import isEmpty from '../Shared/isEmpty';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { appliedFiltersQuery, handleFilterChange } from '../FilterEngine/FilterEngine';
import { defaultSortDirection, initialSortColumnIndex, handleSort } from '../FilterEngine/Sort/sort';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import unpaidInvoiceFilterActive from '../FilterEngine/UnpaidInvoice/unpaidInvoiceFilterActive';
import unpaidInvoiceFilterDefinition from '../FilterEngine/UnpaidInvoice/unpaidInvoiceFilterDefinition';
import InvoiceStatusBadge from '../Shared/InvoiceStatusBadge';
import CancelReasonModalForm from './CancelReasonModalForm';
import MarkAsPaidModalForm from './MarkAsPaidModalForm';
import PaymentOptions from '../Shared/PaymentOptions';
import PnbStats from '../Tailwind/PnbStats';
import createdAtAfterFilterActive from '../FilterEngine/CreatedAtAfter/createdAtAfterFilterActive';
import createdAtBeforeFilterActive from '../FilterEngine/CreatedAtBefore/createdAtBeforeFilterActive';
import createdAtAfterFilterDefinition from '../FilterEngine/CreatedAtAfter/createdAtAfterFilterDefinition';
import createdAtBeforeFilterDefinition from '../FilterEngine/CreatedAtBefore/createdAtBeforeFilterDefinition';

const Invoices = (props) => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadFilters, setLoadFilters] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [lastPage, setLastPage] = useState(null);
  const [stats, setStats] = useState([]);
  const [paymentStatusLoading, setPaymentStatusLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({
    clients: []
  });
  const [mounted, setMounted] = useState(true);
  const [hasAddress, setHasAddress] = useState(true);
  const [hasInvoiceSettings, setHasInvoiceSettings] = useState(true);
  const [showCancelModalForm, setShowCancelModalForm] = useState(false);
  const [markAsPaidModalOptions, setMarkAsPaidModalOptions] = useState({});
  const [currentInvoice, setCurrentInvoice] = useState('');

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();

  const [tableFilters, setTableFilters] = useState(stateConverter(location, props.override || [{ key: 'unpaid', value: ['open'] }]));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const [exportActive, setExportAtive] = useState(false);
  const toggleExportActive = useCallback(() => setExportAtive(!exportActive), [exportActive]);
  const activator = (
    <Button onClick={toggleExportActive} disclosure>
      {t('invoices.export')}
    </Button>
  );

  const downloadInvoice = (id) => {
    axios.get(`/v1/invoices/${id}/download`)
      .then((response) => {
        window.open(response.data.url);
      })
      .catch(() => { });
  };

  const markAsPaidModal = (id) => {
    setMarkAsPaidModalOptions({ id });
  };

  const markAsPaid = (data) => {
    const params = {
      payment_method: data.payment_method
    };

    axios.post(`/v1/invoices/${data.id}/mark_as_paid`, params)
      .then(() => {
        toggleToastActive(t('payments.successfull'));
        fetchInvoices();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });

    setRowActionsActive({});
  };

  let markAsPaidModalForm = null;

  if (Object.keys(markAsPaidModalOptions).length) {
    markAsPaidModalForm = (
      <MarkAsPaidModalForm
        optionalId={markAsPaidModalOptions.id}
        paymentMethod={markAsPaidModalOptions.paymentMethod}
        handleSubmit={markAsPaid}
        participationId={markAsPaidModalOptions.id}
        paid={markAsPaidModalOptions.paid}
        close={() => setMarkAsPaidModalOptions({})}
      />
    );
  }

  const deleteInvoice = useCallback((id) => {
    setCurrentInvoice(id);
    setShowCancelModalForm(true);
  }, []);

  const cleanup = () => {
    setCurrentInvoice('');
    setShowCancelModalForm(false);
  };

  const refetchPaymentStatus = (invoiceId) => {
    setPaymentStatusLoading(true);

    axios.get(`/v1/invoices/${invoiceId}/refetch_status`)
      .then(() => {
        fetchInvoices();
        setPaymentStatusLoading(false);
      })
      .catch(() => { });
  };

  const viewPaymentURL = (pi) => {
    const isDev = process.env.NODE_ENV === 'development';
    let url = '';

    if (isDev) {
      url = `https://dashboard.stripe.com/test/payments/${pi}`;
    } else {
      url = `https://dashboard.stripe.com/payments/${pi}`;
    }

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const finalDeleteInvoice = useCallback((cancelReason) => {
    axios.delete(`/v1/invoices/${currentInvoice}`, { data: cancelReason })
      .then(() => {
        window.location.reload();
        toggleToastActive(t('shared.deleted'));
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  }, [currentInvoice, t, toggleToastActive]);

  let cancellationModalForm = null;

  if (showCancelModalForm) {
    cancellationModalForm = (
      <CancelReasonModalForm
        handleSubmit={finalDeleteInvoice}
        close={() => cleanup()}
      />
    );
  }

  const [rowActionsActive, setRowActionsActive] = useState({});
  // eslint-disable-next-line
  const toggleRowActionsActive = (i) => setRowActionsActive({ [i]: !rowActionsActive[i] });

  const sendInvoiceToClient = (data) => {
    if (!window.confirm(t('invoices.email_to_client_confirmation'))) {
      return;
    }

    axios.post(`/v1/invoices/${data.token}/send_to_client`)
      .then(() => {
        toggleToastActive(t('invoices.email_success'));
        setTimeout(() => {
          fetchInvoices();
        }, 2000);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  const modifier = (tableData) => tableData.map((row, i) => {
    const modifiedRow = [];

    if (row.attachmentAttached && row.number) {
      modifiedRow.push(<Button plain onClick={() => downloadInvoice(row.token)}>{row.number}</Button>);
    } else if (row['editable?']) {
      modifiedRow.push(<Button plain onClick={() => history(`/invoices/${row.token}`)}>{t('invoices.invoice')}</Button>);
    } else {
      modifiedRow.push(
        <Stack alignment="center">
          <Stack.Item>{row.number}</Stack.Item>
          <Tooltip content={t('invoices.available_shortly')}>
            <Icon color="highlight" source={InfoMinor} />
          </Tooltip>
        </Stack>
      );
    }
    if (!props.isClient) {
      modifiedRow.push(<Badge status={props.userId === row.userToken ? 'info' : 'warning'}>{row.user}</Badge>);
      modifiedRow.push(<Button plain onClick={() => history(`/clients/${row.clientToken}`)}>{row.clientName}</Button>);
    }
    modifiedRow.push(row.total);
    modifiedRow.push(moment(row.createdAt).format('ll'));
    modifiedRow.push(row.dueDate ? moment(row.dueDate).format('ll') : null);
    if (!props.isClient) {
      if (row.invoiceState === 'cancellation') {
        modifiedRow.push(
          <Stack alignment="center">
            <Tooltip content={row.cancellationReason}>
              <Icon backdrop source={CircleCancelMajor} color="critical" />
            </Tooltip>
          </Stack>
        );
      } else if (row.invoiceState === 'refunded') {
        modifiedRow.push(
          <Stack alignment="center">
            <Tooltip content={t('invoices.refunded')}>
              <Icon backdrop source={CircleTickMajor} color="interactive" />
            </Tooltip>
          </Stack>
        );
      } else {
        modifiedRow.push(
          <InvoiceStatusBadge active={!row['editable?']} />
        );
      }
    }

    if (row.invoiceState === 'cancellation') {
      modifiedRow.push(t('invoices.cancellation'));
    } else if (row.invoiceState === 'refunded') {
      modifiedRow.push(t('invoices.refunded'));
    } else if (row.processedAt) {
      modifiedRow.push(`${moment(row.processedAt).format('ll')} (${PaymentOptions(t).find((option) => option.value === row.paymentMethod)?.label})`);
    } else {
      modifiedRow.push('');
    }

    if (!props.isClient) {
      modifiedRow.push(row.lastSentAt ? moment(row.lastSentAt).format('llll') : null);
    }

    if (props.hasInvoiceAccess && row.invoiceState !== 'cancellation' && !row['editable?'] && !props.isClient) {
      const actions = [];
      actions.push({
        content: t('invoices.mark_as_paid'),
        icon: CashDollarMajor,
        onAction: () => markAsPaidModal(row.token)
      }, {
        content: t('invoices.cancel_it'),
        destructive: true,
        icon: MobileCancelMajor,
        onAction: () => deleteInvoice(row.token)
      });
      if (!props.isClient && row.clientName) {
        actions.unshift({
          content: t('invoices.email_to_client'),
          icon: EmailMajor,
          onAction: () => sendInvoiceToClient(row)
        });
      }
      actions.push({
        content: 'View Payment',
        icon: CashDollarMajor,
        onAction: () => viewPaymentURL(row.paymentIntent)
      });

      if (row.paymentIntent) {
        actions.push({
          content: 'Refresh status',
          icon: RefreshMajor,
          loading: paymentStatusLoading,
          onAction: () => refetchPaymentStatus(row.token)
        });
      }
      modifiedRow.push(
        <Popover
          active={rowActionsActive[i]}
          activator={<Button plain onClick={() => toggleRowActionsActive(i)} icon={MobileVerticalDotsMajor} />}
          onClose={() => toggleRowActionsActive(i)}
        >
          <ActionList items={actions} />
        </Popover>
      );
    } else {
      modifiedRow.push('');
    }

    if (props.isClient) {
      modifiedRow.push(row.payable ? <Button plain size="small" onClick={() => history(`/invoices/${row.token}/pay`)}>{t('payments.pay_now')}</Button> : '');
    }

    return modifiedRow;
  });

  const appliedFilters = useCallback(() => {
    const af = [];

    if (tableFilters.unpaid) af.push(unpaidInvoiceFilterActive(t, { history, location }, setTableFilters, tableFilters.unpaid));
    if (!isEmpty(tableFilters.client)) {
      af.push(clientFilterActive(t, { history, location }, setTableFilters, filterValues.clients, tableFilters.client));
    }
    if (!tableFilters.createdAtAfter.default) {
      af.push(createdAtAfterFilterActive(t, { history, location }, setTableFilters, tableFilters.createdAtAfter, 'created_at_gteq'));
    }
    if (!tableFilters.createdAtBefore.default) {
      af.push(createdAtBeforeFilterActive(t, { history, location }, setTableFilters, tableFilters.createdAtBefore, 'created_at_lteq'));
    }

    return af;
    // eslint-disable-next-line
  }, [props, t, tableFilters.unpaid, tableFilters.client, filterValues.clients]);

  const fetchInvoices = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/invoices/search', params)
      .then((response) => {
        if (mounted) {
          setStats(response.data.stats);
          setInvoices(response.data.invoices);
          setLastPage(response.data.lastPage);
          setTotalInvoices(response.data.invoiceCount);
          setHasInvoiceSettings(response.data.hasInvoiceSettings);
          setHasAddress(response.data.hasAddress);
          setLoading(false);
        }
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const exportInvoices = () => {
    setBanner([]);
    toggleExportActive();

    if (!window.confirm(t('invoices.export_confirmation'))) {
      return;
    }

    const params = {
      export_params: {
        search: tableFilters.queryValue,
        q: appliedFiltersQuery(appliedFilters())
      }
    };

    if (props.requestId) {
      params.export_params.request_id = props.requestId;
    }

    axios.post('/v1/invoices/export', params)
      .then(() => {
        setLoading(false);
        setBanner([{ title: t('invoices.request_invoices'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
        }
      });
  };

  const filters = [
    unpaidInvoiceFilterDefinition(t, { history, location }, setTableFilters, tableFilters.unpaid),
    clientFilterDefinition(t, { history, location }, setTableFilters, filterValues.clients, tableFilters.client),
    createdAtAfterFilterDefinition(t, { history, location }, setTableFilters, tableFilters.createdAtAfter, tableFilters.createdAtAfterMonthYear),
    createdAtBeforeFilterDefinition(t, { history, location }, setTableFilters, tableFilters.createdAtBefore, tableFilters.createdAtBeforeMonthYear)
  ];

  if (props.isClient) {
    filters.splice(filters.findIndex((i) => i.key === 'client'), 1);
  }

  const placeHolderText = t('invoices.filter');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/invoices', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  const fetchFilterValues = useCallback(() => {
    if (props.isAdmin || props.isInstructor) {
      axios.get('/v1/clients/filter_values')
        .then((res) => {
          setLoadFilters(false);
          setFilterValues(res.data);
        })
        .catch(() => {
          setLoadFilters(false);
        });
    } else {
      setLoadFilters(false);
    }
  }, [props.isAdmin, props.isInstructor]);

  useEffect(() => {
    fetchFilterValues();
  }, [fetchFilterValues]);

  useEffect(() => {
    setMounted(true);
    fetchInvoices();
    return () => {
      setMounted(false);
    };
  }, [fetchInvoices]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const indexSortMapping = {
    number: true,
    client_user_name: true,
    total: false
  };

  let headings = [
    t('invoices.invoice_number'),
    t('staff.staff'),
    t('invoices.client'),
    t('shared.total'),
    t('shared.created_at'),
    t('invoices.due_date'),
    t('invoices.processed'),
    t('user.status'),
    t('invoices.sent_at'),
    ''
  ];

  let columnTypes = [
    'text',
    'text',
    'number',
    'text',
    'text',
    'text',
    'text',
    'text'
  ];

  if (props.isClient) {
    headings = [
      t('invoices.invoice_number'),
      t('shared.total'),
      t('shared.created_at'),
      t('invoices.due_date'),
      t('shared.paid'),
      '',
      ''
    ];

    columnTypes = [
      'text',
      'text',
      'text',
      'text',
      'text',
      'text'
    ];
  }

  const content = (
    <DataTable
      verticalAlign="middle"
      columnContentTypes={columnTypes}
      headings={headings}
      rows={modifier(invoices)}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ location, history }, setTableFilters, indexSortMapping)}
      footerContent={`${invoices.length} ${t('shared.of')} ${totalInvoices}`}
    />
  );

  let loadingContainer = null;
  if (loading) {
    loadingContainer = <Loading />;
  }

  const pnbStatsFilterChange = (action) => {
    if (!action) {
      handleFilterChange({ history, location }, setTableFilters, 'unpaid', 'reset', '')('', '');
    } else {
      handleFilterChange({ history, location }, setTableFilters, 'unpaid')([action]);
    }
  };

  return (
    <Page
      title={t('invoices.invoices')}
      fullWidth
      primaryAction={props.hasInvoiceAccess && hasInvoiceSettings && !props.isClient ? { icon: PlusMinor, content: t('invoices.create'), onAction: () => history('/invoices/create') } : null}
      separator
    >
      {!props.hideStats && <PnbStats stats={stats} onClick={pnbStatsFilterChange} activeFilter={tableFilters.unpaid?.[0]} />}
      {markAsPaidModalForm}
      {cancellationModalForm}
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      {!hasAddress && !props.isAccountant && (
        <Banner title={t('invoices.missing_address')} status="warning">
          <p>{t('shared.missing_address')}</p>
        </Banner>
      )}
      {!hasInvoiceSettings && !props.isAccountant && (
        <Banner title={t('invoices.invoice_settings')} status="warning">
          <p>{t('shared.missing_invoice_settings')}</p>
          <Button onClick={() => history('/invoice_settings')}>{t('settings.settings')}</Button>
        </Banner>
      )}
      {toastMarkup}
      {loadingContainer}
      {!loading && !loadFilters ? (
        <Card sectioned>
          <Card.Subsection>
            <Stack>
              <Stack.Item fill>
                {filterControl}
              </Stack.Item>
              {props.isAdmin || props.isInstructor || props.isAccountant ? (
                <Popover active={exportActive} activator={activator} onClose={toggleExportActive}>
                  <ActionList
                    items={[
                      { content: t('invoices.export_via_email'), icon: ExportMinor, onAction: () => exportInvoices() }
                    ]}
                  />
                </Popover>
              ) : null}
            </Stack>
          </Card.Subsection>
          <Card.Subsection>
            {content}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={invoices}
        lastPage={lastPage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  isAdmin: state.auth.admin,
  isInstructor: state.auth.instructor,
  isClient: state.auth.client,
  isAccountant: state.auth.role === 'accountant',
  userId: state.auth.token
});

export default connect(mapStateToProps)(Invoices);
