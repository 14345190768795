const PaymentOptions = (t) => {
  const options = [{
    label: t('payments.cash'),
    value: 'cash'
  }, {
    label: t('payments.bank_debit_ach'),
    value: 'bank_debit_ach'
  }, {
    label: t('payments.wire'),
    value: 'wire'
  }, {
    label: t('plan.credit_card'),
    value: 'credit_card'
  }, {
    label: t('payments.check'),
    value: 'check'
  }, {
    label: t('payments.other'),
    value: 'other'
  }, {
    label: 'Stripe',
    value: null
  }, {
    label: 'Stripe',
    value: 'Other'
  }, {
    label: t('gift_cards.singular'),
    value: 'Gift card'
  }, {
    label: t('products.lesson_package'),
    value: 'event_package'
  }, {
    label: 'PayPal',
    value: 'paypal'
  }];

  return options;
};

export default PaymentOptions;
