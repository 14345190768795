/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormLayout, Toast, Modal, Form, Badge, Stack, Card, Button, Banner } from '@shopify/polaris';
import axios from 'axios';
import { connect } from 'react-redux';
import { BlockMinor, ProductsMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import Banners from '../Shared/Banners';
import ExternalLink from '../Shared/ExternalLink';

const DisplayEvent = (props) => {
  const [event, setEvent] = useState({ ...props.event });
  const [loadingUrl, setLoadingUrl] = useState(false);
  const [zoomStartUrl, setZoomStartUrl] = useState('');
  const [participations, setParticipations] = useState([]);
  const [showParticipationLimit, setShowParticipationLimit] = useState(false);
  const [banner, setBanner] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setShowParticipationLimit(Boolean(event.participation_limit));
  }, [event.participation_limit]);

  const history = useNavigate();

  const handleDelete = useCallback(() => {
    props.close();
    props.handleDelete(event);
  }, [props, event]);

  const participationBadges = () => {
    const badges = (participations || []).map((p) => (
      <Badge key={p.id}>{p.label}</Badge>
    ));

    return <Stack>{badges}</Stack>;
  };

  const fetchDetails = () => {
    const params = {
      date: event.start_time
    };

    axios.post(`/v1/events/${event.id}/details`, params)
      .then((response) => {
        setEvent(response.data.event);
        setParticipations(response.data.participations);
      })
      .catch(() => { });
  };

  const getZoomStartUrl = () => {
    setLoadingUrl(true);
    axios.get(`/v1/events/${event.id}/zoom_start_url`)
      .then((response) => {
        setZoomStartUrl(response.data.start_url);
      })
      .catch(() => { })
      .then(() => setLoadingUrl(false));
  };

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line
  }, []);

  const eventOptions = () => (
    <Card>
      <Card.Section title={t('shared.start')}>
        {moment(event.start).format('LLLL')}
      </Card.Section>
      <Card.Section title={t('shared.end')}>
        {moment(event.end).format('LLLL')}
      </Card.Section>
      {participations.length ? (
        <Card.Section title={t('shared.participants')}>
          {participationBadges()}
        </Card.Section>
      ) : ''}
      {event.price ? (
        <Card.Section title={t('shared.price')}>
          {event.pretty_price}
        </Card.Section>
      ) : ''}
      {event.description ? (
        <Card.Section title={t('shared.description')}>
          {event.description}
        </Card.Section>
      ) : ''}
      {event.location ? (
        <Card.Section title={t('address.location')}>
          {event.location}
        </Card.Section>
      ) : ''}
      {showParticipationLimit ? (
        <Card.Section title={t('shared.participation_limit')}>
          {event.participation_limit}
        </Card.Section>
      ) : ''}
      {event.online ? (
        event.creator && (event.videoPreference === 'Zoom') ? (
          <Card.Section title="Online Event URL">
            {zoomStartUrl ? (
              <ExternalLink hideIcon url={zoomStartUrl} label={zoomStartUrl} />
            ) : (
              <Button loading={loadingUrl} onClick={() => getZoomStartUrl()}>
                {t('event.get_link')}
              </Button>
            )}
          </Card.Section>
        ) : (
          <Card.Section title="Online Event URL">
            <ExternalLink hideIcon url={event.conferenceUrl} label={event.conferenceUrl} />
          </Card.Section>
        )
      ) : ''}
    </Card>
  );

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const makeEventBookable = () => {
    if (!window.confirm(t('event.bookable_product_on_your_booking_page'))) {
      return;
    }

    axios.post('/v1/products/create_recurring_product', { event_token: event.id })
      .then(() => {
        toggleToastActive(t('shared.success'));
        fetchDetails();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          toggleToastActive(`${t('shared.something_went_wrong')} - ${error.response.data.errors.joins(' - ')}`);
        } else {
          toggleToastActive(error.response.data.error);
        }
      });
  };

  const secondaryActions = () => {
    const actions = [{
      destructive: true,
      content: t('shared.delete'),
      onAction: handleDelete
    }];

    if (!props.hideSettings) {
      actions.unshift({
        content: t('shared.details'),
        onAction: () => history(`/events/${event.id}?calendar=true`)
      });
    }

    if (event.product_token) {
      actions.unshift({
        icon: ProductsMinor,
        content: t('event.product'),
        onAction: () => history(`/products/${event.product_token}`)
      });
    } else {
      actions.unshift({
        icon: BlockMinor,
        content: t('event.make_event_bookable'),
        onAction: () => makeEventBookable()
      });
    }

    return actions;
  };

  return (
    <Modal
      open={props.active}
      onClose={() => props.close()}
      title={event.title}
      primaryAction={{
        content: t('shared.edit'),
        onAction: props.handleSubmit
      }}
      secondaryActions={secondaryActions()}
    >
      {toastMarkup}
      <Modal.Section>
        <Form onSubmit={props.handleSubmit}>
          <FormLayout>
            <Banners banners={banner} onDismissBanner={() => setBanner([])} />
            {event.temp && (
              <Banner status="info">
                {t('booking.temporary')}
              </Banner>
            )}
            {eventOptions()}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  canDoOnlineEvent: state.auth.canDoOnlineEvent
});

export default connect(mapStateToProps)(DisplayEvent);
